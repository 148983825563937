<template>
  
    <div class="lg:order-1 lg:w-1/4 lg:p-5 pb-5 px-5" data-aos="fade">

        <div class="my-5 p-5 bg-forest text-white font-light tracking-wider text-smd transform hover:scale-1025 transition duration-500 ease-in-out motion-reduce:transition-none motion-reduce:transform-none" >
            <div class="font-display text-md leading-none pb-2" v-html="coNum">
                57.6–159
            </div>
            GIGATONS
            <div class="text-sm pt-2">CO2 EQUIVALENT <br> REDUCED / SEQUESTERED (2020–2050)</div>
        </div>

        <div v-if="keyWords != 'no'">
            <div class="bg-sun p-2 text-white text-base text-center tracking-wide" >
                KEY WORDS
            </div>
            <div class="bg-sun25">
                <div class="p-5" v-html="keyWords">
                    key words
                </div>
            </div>
        </div>

        <router-link to="/glossary">
            <div class="transition duration-500 ease-in-out bg-forest hover:bg-leaf50 text-white hover:text-forest text-center my-5 p-5 text-smd rounded-md transform hover:scale-1025 motion-reduce:transform-none motion-reduce:transition-none">
                VISIT THE GLOSSARY
            </div>
        </router-link>

        <a v-bind:href="solLink" target="_blank">
            <div class="transition duration-500 ease-in-out bg-drawdown hover:bg-sky text-white hover:text-drawdown text-center my-5 p-5 text-smd rounded-md transform hover:scale-1025 motion-reduce:transform-none motion-reduce:transition-none">
                VIEW THE ORIGINAL SOLUTION
            </div>
        </a>
        <a href="../assets/doc/dd-top-20.pdf" download="Climate-Solutions-For-Kids-Top-20">
            <div class="transition duration-500 ease-in-out bg-drawdown hover:bg-sky text-white hover:text-drawdown text-center my-5 p-5 text-smd rounded-md transform hover:scale-1025 motion-reduce:transform-none motion-reduce:transition-none">
                DOWNLOAD THE TOP 20 SOLUTIONS TO PRINT
            </div>
        </a>
        <!-- <a v-bind:href="solPDF" download>
            <div class="transition duration-500 ease-in-out bg-drawdown hover:bg-sky text-white hover:text-drawdown text-center my-5 p-5 text-smd rounded-md transform hover:scale-1025 motion-reduce:transform-none motion-reduce:transition-none">
                DOWNLOAD THIS SOLUTION TO PRINT
            </div>
        </a> -->
        <!-- <a class="text-drawdown italic hover:underline transition duration-500 ease-in-out" href="../assets/doc/dd-top-20.pdf" download="Climate-Solutions-For-Kids-Top-20">
            or download all Top 20 Solutions to print
        </a> -->
        
    </div>

    
</template>

<script>
import { mapState, mapMutations} from "vuex";
export default {
  name: 'SolutionHeader',
  props: {
    coNum: String,
    keyWords: String,
    solLink: String,
    solPDF: String
  },
  computed: {
      ...mapState(["solutionIndex"])
    },

    methods: {
      ...mapMutations(["increaseSolutionIndex", "decreaseSolutionIndex"])
    }
}

</script>